import React from "react"
import ImageMeta from "../components/ImageMeta"
import Button from "@bit/azheng.joshua-tree.button"
import Layout from "../components/layout"
import SEO from "../components/seo"

const CareFundPage = ({ data, location }) => {
  return (
    <Layout language="en">
      <SEO title="CareFund℠" description="Learn more about CareFund℠" />

      <div
        style={{
          marginTop: "150px",
          marginBottom: "100px",
          maxWidth: "1080px",
          marginInline: "auto",
          padding: "0 40px",
        }}
      >
        <h1 style={{ fontSize: "36px" }}>Financial & Insurance Information</h1>
        <p style={{ padding: 0 }}>
          We aim to deliver the highest quality oral surgery care at a
          reasonable cost to our patients. We accept cash, checks, all major
          credit cards, and CareCredit<sup>®</sup>. If you have questions
          regarding our financial policies or your existing account, please{" "}
          <a href="/contact-us/">contact</a> our knowledgeable staff.
        </p>
        <p style={{ padding: 0 }}>
          As always, we are also here to help you{" "}
          <a href="/contact-us/">request your consultation</a> with Dr. Hudson
          to answer any questions you may have about how we can help you regain
          your oral health and smile. Reach out to us at any time.
        </p>
        <div style={{ maxWidth: "300px" }}>
          <ImageMeta
            style={{ display: "block", margin: "50px 0 20px 0" }}
            publicId="HOSDI/DEV/CareFund-Logo"
            alt="CareFund Logo"
          />
        </div>
        <p style={{ padding: 0, marginTop: "30px" }}>
          Get the care you need now and pay later with CareFund's interest-free
          financing for up to 12 months.
        </p>

        <Button
          buttonText="Apply Now"
          href="https://getcarefund.com/"
          external
          contained
        />
      </div>
    </Layout>
  )
}

export default CareFundPage
